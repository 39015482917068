import React from "react";
import { Carousel } from "react-bootstrap";

import image1 from "./../assets/images/inflables/7.png";
import image2 from "./../assets/images/inflables/5.png";
import image3 from "./../assets/images/inflables/9.png";

const CarouselContainer = () => {
  return (
    <Carousel fade={true} pause={false}>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 h-auto image-carousel"
          src={image1}
          alt="First slide"
        />

        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 h-auto image-carousel"
          src={image2}
          alt="Third slide"
        />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 h-auto image-carousel"
          src={image3}
          alt="Third slide"
        />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselContainer;
