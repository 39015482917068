import "./App.css";
import styled from "styled-components";
import CarouselContainer from "./component/CarouselContainer";
import Question from "./component/Questions/Question";
import Service from "./component/Service/Service";
import Contact from "./component/Contact";
import Presentation from "./component/Nosotros/Presentation";

function App() {
  return (
    <div>
      <CarouselContainer />
      <Section>
        <Presentation />
        <Service />
      </Section>
      <Question />
      <Contact />
    </div>
  );
}

export default App;

const Section = styled.div`
  margin: 40px 60px 0px 60px;
  @media (max-width: 480px) {
    margin: 10px;
  }
`;
