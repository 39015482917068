const services = [
  {
    id: 1,
    title: "Castillo Inflable",
    image: "/img/castillo.png",
    color: "#84b6f4",
    titleBack: "castillo",
    description: "3 x 3 mts. Castillo multicolor.",
  },

  {
    id: 2,
    title: "Tobogan Inflable",
    image: "/img/inflable.png",
    color: "#fdfd96",
    titleBack: "Tobogan ",
    description: "6x4x5 mts. Sonrisas enormes!",
  },

  {
    id: 3,
    title: "Ring inflable",
    image: "/img/ring.png",
    color: "#fdcae1",
    titleBack: "Ring de Boxeo",
    description:
      "4 x 4 mts. Ring de boxeo, viene con guantes gigantes, para grandes y chicos.",
  },

  {
    id: 4,
    title: "personaje inflable",
    image: "/img/coco.png",
    color: "#ff6565",
    titleBack: "Cocodrilos",
    description: "5 x 3 mts. Una aventura inolvidable!",
  },

  {
    id: 5,
    title: "metegol",
    image: "/img/mete.png",
    color: "#77dd77",
    titleBack: "Metegol",
    description: "Medidas: 1,25 x 75 cm. 100% Aluminio",
  },

  {
    id: 6,
    title: "ping pong",
    image: "/img/pong.png",
    color: "#F5DC56",
    titleBack: "ping pong",
    description: "Medidas: 1,40 x 90 cm.",
  },
  {
    id: 7,
    title: "Mini Pool",
    image: "/img/pool.png",
    color: "#d8af97",
    titleBack: "Mini Pool",
    description: "Medidas:1,43 x 86 cm.",
  },

  {
    id: 8,
    title: "Karaoke",
    image: "/img/karaoke.png",
    color: "#FC9D5D",
    titleBack: "karaoke",
    description:
      "Sonido completo con 2 bafles  en trípode, consola y 2 microfonos inalambricos, proyector HD",
  },

  {
    id: 9,
    title: "Juego  De Luces",
    image: "/img/luces.png",
    color: "#A1BEDD",
    titleBack: "Juego de Luces",
    description: "CONVERTI TU CASA EN UN BOLICHE!! Luces y Sonido completo!.",
  },
];

export default services;
