import React from "react";
import { Fade } from "react-reveal";

function CardQuestion({ ques }) {
  return (
    <Fade bottom duration={2000} distance="20px">
      <div className="container-question">
        <div className="box-img">
          <img className="image-card" src={ques.image}></img>
        </div>
        <div className="below-card">
          <h3 className="question-card">{ques.question}</h3>
          <div className="description-card">{ques.description}</div>
        </div>
      </div>
    </Fade>
  );
}

export default CardQuestion;
