import React from "react";
import BannerImg from "./BannerImg";
import "./greeting.css";
import { Fade } from "react-reveal";

function Presentation() {
  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1 className="greeting-text">Sobre Nosotros</h1>

              <p className="greeting-text-p subTitle">
                Somos una empresa familiar especializada en el entretenimiento y
                recreación móvil. Llevamos toda la diversión al lugar que
                nuestro cliente lo solicite para eventos familiares o
                corporativos. Ofrecemos alquiler de gran variedad juegos,
                animación y organización integral del área de entretenimiento.
              </p>
              <p className="greeting-text-p subTitle">
                Contamos con una amplia zona de cobertura que incluye Zona sur y
                Capital Federal.
              </p>
            </div>
          </div>

          <div className="greeting-image-div">
            <BannerImg className="greeting-img-div-card" />
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default Presentation;
