import React, { useState } from "react";
import "../../App.css";
import { Fade } from "react-reveal";
function Card({ flashcard }) {
  const [flip, setFlip] = useState(false);
  return (
    <div
      className={`card ${flip ? "flip" : ""}`}
      onClick={() => setFlip(!flip)}
    >
      <Fade bottom duration={2000} distance="20px">
        <div className="front" style={{ backgroundColor: flashcard.color }}>
          <div className="title">
            <h2>{flashcard.title}</h2>
          </div>
          <div className="box-img">
            <img className="image-card-serv" src={flashcard.image}></img>
          </div>
        </div>
      </Fade>
      <div className="back" style={{ backgroundColor: flashcard.color }}>
        <div className="description">
          <h3>{flashcard.titleBack}</h3>

          <div className="flashcard-description">
            <ul>
              <li>{flashcard.description}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
