import React, { Component } from "react";
import banner from "./juegos.png";

//para svg estaticos
class BannerImg extends Component {
  render() {
    return (
      <div className="App">
        <div className="container">
          <img
            src={banner}
            alt=""
            width="450"
            className="greeting-img-div-card"
          />
        </div>
      </div>
    );
  }
}
export default BannerImg;
